import React from "react";
import Homepage from "./Pages/Homepage/Homepage";
import "./sass/App.scss";
import { Route, Routes } from "react-router-dom";

import Navbar from "./Navbar/Navbar";
import HowItWorks from "./Pages/HowItWorks/HowItWorks";
import { useGlobalContext } from "./context";
import HomepageMobile from "./Pages/Homepage/HomepageMobile";
import Team from "./Pages/Team/Team";
import WhoIsItFor from "./Pages/WhoIsItFor/WhoIsItFor";

function App() {
  const { isMobile } = useGlobalContext();

  return (
    <div className="App">
      <Navbar />
      <Routes>
        {isMobile ? (
          <>
            <Route path="/" element={<HomepageMobile />} />
            <Route path="/howitworks" element={<HowItWorks />} />
            <Route path="/team" element={<Team />} />
            <Route path="/whoisitfor" element={<WhoIsItFor />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Homepage />} />
            <Route path="/howitworks" element={<HowItWorks />} />
            <Route path="/team" element={<Team />} />
            <Route path="/whoisitfor" element={<WhoIsItFor />} />
          </>
        )}
      </Routes>
    </div>
  );
}

export default App;
