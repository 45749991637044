import "./Team.scss";

import React, { useEffect, useLayoutEffect, useState } from "react";
import { personData } from "../../Data/Data";
import ButtonRound from "../../Buttons/ButtonRound";
import { useGlobalContext } from "../../context";

const Team = () => {
  const { closeNavbarLinks, videoDimensions, setVideoDimensions } =
    useGlobalContext();

  useEffect(() => {
    // Function to get and store the initial width
    const getInitialWidth = () => {
      const elements = document.getElementsByClassName("team-container");

      // Convert the NodeList to an array and get the first element
      const element = Array.from(elements)[0];

      // Check if the element is valid
      if (element instanceof Element) {
        // Get the initial width using getBoundingClientRect()
        const width = element.getBoundingClientRect().width + 20;

        // Set the initial width in the state
        setVideoDimensions({
          width: width,
        });
      }
    };

    // Call the function when the component mounts
    getInitialWidth();

    // Function to handle window resize
    const handleResize = () => {
      // Update the element width on window resize
      getInitialWidth();
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window]);

  return (
    <div className="team" onClick={closeNavbarLinks}>
      <ButtonRound />
      <div className="team-container-1">
        <h1 className="team-title">Meet the team</h1>
      </div>
      <div className="team-container-2">
        <div className="team-section-1">
          <div className="team-container">
            {personData.map((data, index) => (
              <div key={index} className="team-person">
                <div className="team-person--pictures">
                  <img
                    src={data.img}
                    alt="logo"
                    className="team-person--img"
                    loading="lazy"
                  />
                </div>

                <div className="team-person--text">
                  <p className="team-person--name">{data.name}</p>
                  <p className="team-person--job">{data.job}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="section-2">
          <div className="video">
            <div className="mp4">
              <iframe
                width={videoDimensions.width}
                height={(videoDimensions.width * 9) / 16}
                src="https://www.youtube.com/embed/-zugkNObFC4"
                frameBorder="0"
                allowFullScreen
                title="YouTube Video"
                style={{ borderRadius: "1rem" }}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Team;
