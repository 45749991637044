import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { links } from "./../Data/Data";
import logo from "../img/logo-02.2.4.png";
import "./Navbar.scss";
import { useGlobalContext } from "../context";

const Navbar = () => {
  const { linksContainerRef, linksRef, toggleLinks, setShowLinks } =
    useGlobalContext();
  const { pathname } = useLocation();
  const currentPage = pathname.slice(1);

  const handleLinkClick = () => {
    setShowLinks(false);
  };

  return (
    <nav>
      <div className="nav-center">
        <div className="nav-header">
          <Link to={"/"}>
            <img src={logo} className="logo" alt="logo" />
          </Link>
          <button className="nav-toggle" onClick={toggleLinks}>
            <FaBars />
          </button>
        </div>
        <div className="links-container" ref={linksContainerRef}>
          <ul className="links" ref={linksRef}>
            {links.map((link) => {
              const { id, url, text } = link;
              return (
                <li key={id} onClick={handleLinkClick}>
                  <Link to={url}>
                    <h2
                      className={`${
                        currentPage === url ? "link-active" : "link "
                      }`}
                    >
                      {text}
                    </h2>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
