import "../HowItWorks/HowItWorks.scss";

import React, { useEffect } from "react";
import ButtonRound from "../../Buttons/ButtonRound";
import { useGlobalContext } from "../../context";

const WhoIsItFor = () => {
  const { closeNavbarLinks, videoDimensions, setVideoDimensions } =
    useGlobalContext();

  useEffect(() => {
    // Function to get and store the initial width
    const getInitialWidth = () => {
      const elements = document.getElementsByClassName("text-container");

      // Convert the NodeList to an array and get the first element
      const element = Array.from(elements)[0];

      // Check if the element is valid
      if (element instanceof Element) {
        // Get the initial width using getBoundingClientRect()
        const width = element.getBoundingClientRect().width - 50;

        // Set the initial width in the state
        setVideoDimensions({
          width: width,
        });
      }
    };

    // Call the function when the component mounts
    getInitialWidth();

    // Function to handle window resize
    const handleResize = () => {
      // Update the element width on window resize
      getInitialWidth();
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window]);

  return (
    <div className="howitworks" onClick={closeNavbarLinks}>
      <ButtonRound />
      <div className="container-1">
        <h1 className="title">Who is it for?</h1>
      </div>
      <div className="container-2">
        <div className="section-1">
          <div className="text-container">
            <p className="text">
              Families in large cities with pets and cars, digital nomads, and
              others who work remotely, multi-property owners, building
              maintenance companies, and building managers will have the
              opportunity to make their lives and businesses easier by using
              PlanIt. The PlanIt platform aims to engage users in improving
              their everyday habits with planning, creating a better mindset to
              face everyday challenges. PlanItAll will reduce the stress of
              tight schedules for families, ensuring that important tasks are
              not forgotten or missed.
              <br />
              Ensure a seamless experience for scheduling appointments with
              mechanics or dentists. Stay informed about planned maintenance of
              electricity, roads, and buildings in your area. Small businesses
              can effortlessly manage client reservations through an intuitive
              calendar system that syncs with clients' calendars. Building
              managers can efficiently and transparently communicate essential
              information to tenants without causing unnecessary disruptions.
              Companies can easily disseminate crucial information to clients,
              reducing the pressure on their call centers.
            </p>
            <p className="text">
              Save your time and avoid unpleasent situations.
            </p>
          </div>
        </div>

        <div className="section-2">
          <div className="video">
            <div className="mp4">
              <iframe
                width={videoDimensions.width}
                height={(videoDimensions.width * 9) / 16}
                src="https://www.youtube.com/embed/oaywyFIQ9cM"
                frameBorder="0"
                allowFullScreen
                title="YouTube Video"
                style={{ borderRadius: "1rem" }}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoIsItFor;
