import React from "react";
import "./ButtonRound.scss";
import { Link } from "react-router-dom";

const ButtonRound = () => {
  return (
    <Link to={"/"}>
      <button className="button">
        <span className="circle" aria-hidden="true">
          <span className="icon arrow"></span>
        </span>
        <span className="button-text">back</span>
      </button>
    </Link>
  );
};

export default ButtonRound;
