import "./HowItWorks.scss";

import React, { useEffect } from "react";
import ButtonRound from "../../Buttons/ButtonRound";
import { useGlobalContext } from "../../context";

const HowItWorks = () => {
  const { closeNavbarLinks, videoDimensions, setVideoDimensions } =
    useGlobalContext();

  useEffect(() => {
    // Function to get and store the initial width
    const getInitialWidth = () => {
      const elements = document.getElementsByClassName("text-container");

      // Convert the NodeList to an array and get the first element
      const element = Array.from(elements)[0];

      // Check if the element is valid
      if (element instanceof Element) {
        // Get the initial width using getBoundingClientRect()
        const width = element.getBoundingClientRect().width - 50;

        // Set the initial width in the state
        setVideoDimensions({
          width: width,
        });
      }
    };

    // Call the function when the component mounts
    getInitialWidth();

    // Function to handle window resize
    const handleResize = () => {
      // Update the element width on window resize
      getInitialWidth();
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window]);

  return (
    <div className="howitworks" onClick={closeNavbarLinks}>
      <ButtonRound />
      <div className="container-1">
        <h1 className="title">How it works</h1>
      </div>
      <div className="container-2">
        <div className="section-1">
          <div className="text-container">
            <p className="text">
              PlanIt focuses on advanced information management to improve the
              quality of life in European cities. Everything you need is in one
              place and at the right time.
              <br />
              Planit is used to collect all selected notifications that the user
              may forget or miss. Users will be more satisfied with the service
              itself if its interruptions are avoided or, on the other hand,
              they do not miss the requested service. Users can/will synchronize
              Planit with their own calendars and avoid browsing sites and other
              actions that take up unnecessary time. Users of the Planit
              platform will have all pre-selected company services (utility
              infrastructure services, building maintenance, building council
              activities, auto mechanics, dentists, healthcare companies) parsed
              by specific user/address for all types of services. The Planit
              platform provides the shortest path from the information owner to
              the information user and vice-versa. The first type of users are
              mostly consumers of information - with one address. The second
              type of users are predominantly information owners (utility
              companies), and the third type of users are small business owners
              that will have their own private network of Planit users for
              improving their own services. Each type of user is responsible for
              their information on the platform. Planit will be implemented as a
              progressive web application with security protocols that enable
              secure data exchange between client and server. Planit users will
              receive online and offline notifications and use the same app on
              mobile devices and notebook computers with the same interface.
              Planit shall also send real-time notifications of unplanned
              service outages to a specified user and their appointed address.
            </p>
            <p className="text">Everything in one place with PlanIt!</p>
          </div>
        </div>

        <div className="section-2">
          <div className="video">
            <div className="mp4">
              <iframe
                width={videoDimensions.width}
                height={(videoDimensions.width * 9) / 16}
                src="https://www.youtube.com/embed/3DtVbeF1O-g"
                frameBorder="0"
                allowFullScreen
                title="YouTube Video"
                style={{ borderRadius: "1rem" }}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
