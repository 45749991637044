import "./Login.scss";
import React from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import { GoPersonFill } from "react-icons/go";
import { BiSolidLock } from "react-icons/bi";

function Login() {
  return (
    <div className="login">
      <form className="login-form">
        <span>
          <GoPersonFill className="login-form--user" />
        </span>
        <input
          className="login-form--email"
          autoFocus
          maxLength="25"
          placeholder="Email"
          type="email"
          // value={}
          required
        />
        <span>
          <BiSolidLock className="login-form--lock" />
        </span>
        <input
          className="login-form--password"
          autoComplete="off"
          maxLength="8"
          placeholder="Password"
          type="password"
          // value={}
          required
        />
        <button className="login-form--submit" type="submit" value="Log in">
          Log in
        </button>
      </form>
      <h2 className="login-signup">
        "Not a member?"
        <a className="login-signup--link" href="/">
          Sign up now
        </a>
        <FaLongArrowAltRight className="login-signup--arrow" />
      </h2>
    </div>
  );
}

export default Login;
