import "./LoginMobile.scss";
import React from "react";
import { FaLongArrowAltRight, FaTimes } from "react-icons/fa";
import { GoPersonFill } from "react-icons/go";
import { BiSolidLock } from "react-icons/bi";
import { useGlobalContext } from "../../../context";

function LoginMobile() {
  const { closeLogin } = useGlobalContext();

  const handleButtonCloseClick = (event) => {
    event.stopPropagation(); // Prevent event bubbling
    closeLogin();
  };

  return (
    <div className="mobile-login">
      <button
        className="mobile-login--close_btn"
        onClick={handleButtonCloseClick}
      >
        <FaTimes />
      </button>
      <div className="mobile-homepage--phrase">
        <h1 className="mobile-homepage--phrase_title">Plan it all...</h1>
      </div>
      <form className="mobile-login--form">
        <div>
          <span>
            <GoPersonFill className="mobile-login--form_user" />
          </span>
          <input
            className="mobile-login--form_email"
            autoFocus
            maxLength="25"
            placeholder="Email"
            type="email"
            // value={}
            required
          />
        </div>
        <div>
          <span>
            <BiSolidLock className="mobile-login--form_lock" />
          </span>
          <input
            className="mobile-login--form_password"
            autoComplete="off"
            maxLength="8"
            placeholder="Password"
            type="password"
            // value={}
            required
          />
        </div>
        <button
          className="mobile-login--form_submit"
          type="submit"
          value="Log in"
        >
          Log in
        </button>
      </form>
      <h2 className="mobile-login--signup">
        "Not a member?"
        <a className="mobile-login--signup_link" href="/">
          Sign up now
        </a>
        <FaLongArrowAltRight className="mobile-login--signup_arrow" />
      </h2>
    </div>
  );
}

export default LoginMobile;
