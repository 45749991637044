import React from "react";
import "./HomepageMobile.scss";
import { useGlobalContext } from "../../context";
import LoginMobile from "./Login/LoginMobile";

const HomepageMobile = () => {
  const { openLogin, isLoginOpen, closeNavbarLinks } = useGlobalContext();
  return (
    <div
      className="homepage-mobile"
      onClick={() => {
        openLogin();
        closeNavbarLinks();
      }}
    >
      {isLoginOpen ? <LoginMobile /> : <></>}
    </div>
  );
};

export default HomepageMobile;
