import "./Hompage.scss";
import React from "react";
import Login from "./Login/Login";

function Homepage() {
  return (
    <div className="homepage">
      <div className="homepage-phrase">
        <h2 className="homepage-phrase--title">
          Plans are nothing;
          <br />
          Planing is everything!
        </h2>
      </div>
      <Login />
    </div>
  );
}

export default Homepage;
