import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [showLinks, setShowLinks] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const linksContainerRef = useRef(null);
  const linksRef = useRef(null);

  //video
  const [videoDimensions, setVideoDimensions] = useState({
    width: 10,
  });
  const closeNavbarLinks = () => {
    setShowLinks(false);
  };

  const toggleLinks = () => {
    setShowLinks(!showLinks);
  };

  const openLogin = () => {
    setIsLoginOpen(true);
  };
  const closeLogin = () => {
    setIsLoginOpen(false);
  };

  useEffect(() => {
    const linksHeight = linksRef.current.getBoundingClientRect().height;
    if (showLinks) {
      linksContainerRef.current.style.height = `${linksHeight}px`;
    } else {
      linksContainerRef.current.style.height = "0px";
    }
  }, [showLinks]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    // Function to get and store the initial width
    const getInitialWidth = () => {
      const elements = document.getElementsByClassName("about-section--3");

      // Convert the NodeList to an array and get the first element
      const element = Array.from(elements)[0];

      // Check if the element is valid
      if (element instanceof Element) {
        // Get the initial width using getBoundingClientRect()
        const width = element.getBoundingClientRect().width;

        // Set the initial width in the state
        setVideoDimensions({
          width: width,
        });
      }
    };

    // Call the function when the component mounts
    getInitialWidth();

    // Function to handle window resize
    const handleResize = () => {
      // Update the element width on window resize
      getInitialWidth();
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <AppContext.Provider
      value={{
        showLinks,
        linksContainerRef,
        linksRef,
        toggleLinks,
        closeNavbarLinks,
        setIsMobile,
        isMobile,
        isLoginOpen,
        openLogin,
        closeLogin,
        setIsLoginOpen,
        videoDimensions,
        setVideoDimensions,
        setShowLinks,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
