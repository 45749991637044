import toza_img from "../img/team/svetozar.jpg";
import nedja_img from "../img/team/nedeljko.jpg";
import sasa_img from "../img/team/sasa.jpg";
import sava_img from "../img/team/Sava-1.jpg";

///////HEADER
export const headerData = [
  {
    href: "about",
  },
];

export const links = [
  {
    id: 1,
    url: "whoisitfor",
    text: "Who is it for",
  },
  {
    id: 2,
    url: "howitworks",
    text: "How it works",
  },
  {
    id: 3,
    url: "team",
    text: "Team",
  },
];
export const personData = [
  {
    name: "Nedeljko Popović",
    job: "Finance & front-end developent",
    img: nedja_img,
  },
  {
    name: "Sava Simić",
    job: "Fractional CTO",
    img: sava_img,
  },
  {
    name: "Svetozar Petrović",
    job: "Design & Marketing",
    img: toza_img,
  },
  {
    name: "Saša Zdravković",
    job: "CEO",
    img: sasa_img,
  },
];
